import React, { useEffect, useState } from 'react'
import { func, number, string, bool } from 'prop-types'
import { IconButton, Stack, Typography, styled } from '@mui/material'
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import { useTest } from '@hooks/useTest'

const Qty = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.dark,
  fontWeight: 600,
}))

const Quantity = ({ initialQuantity = 1, limit = 10, onDecrement, onIncrement, size = 'small', readOnly = false }) => {
  const [quantity, setQuantity] = useState(initialQuantity)
  const { generateTestId } = useTest()

  const onDecrementHandler = () => {
    setQuantity(previousQuantity => {
      onDecrement(previousQuantity - 1)
      return previousQuantity - 1
    })
  }
  const onIncrementHandler = () => {
    setQuantity(previousQuantity => {
      onIncrement(previousQuantity + 1)
      return previousQuantity + 1
    })
  }

  useEffect(() => {
    setQuantity(initialQuantity)
  }, [initialQuantity])

  return (
    <Stack direction="row" justifyContent="center" alignItems="center" spacing={size === 'small' ? 1 : 0}>
      <IconButton
        size={size}
        aria-label="subtract"
        onClick={onDecrementHandler}
        disabled={quantity < 2 || readOnly}
        data-testid={generateTestId('quantity', 'removeButton')}
        sx={size === 'small' ? { padding: 0 } : null}
      >
        <RemoveCircleOutlineIcon fontSize={size} />
      </IconButton>

      <Qty variant="body1" data-testid={generateTestId('quantity', 'count')}>
        {quantity}
      </Qty>

      <IconButton
        size={size}
        aria-label="add"
        onClick={onIncrementHandler}
        disabled={quantity >= limit || readOnly}
        data-testid={generateTestId('quantity', 'addButton')}
        sx={size === 'small' ? { padding: 0 } : null}
      >
        <AddCircleOutlineIcon fontSize={size} />
      </IconButton>
    </Stack>
  )
}

Quantity.propTypes = {
  initialQuantity: number,
  limit: number,
  onDecrement: func,
  onIncrement: func,
  size: string,
  readOnly: bool,
}

export default Quantity

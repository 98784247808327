import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { getRegionZone } from '@helpers/geo-location'
import { currencyFormatUS } from '@helpers/string-helper'
import { styled, Button, Box } from '@mui/material'
import Price from '@rtg2022/components/atoms/Price/Price'
import PriceAtom from '@rtg2022/components/atoms/ProductPrice/PriceAtom'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import { fetchProductBySku } from '@services/product'
import { addToCart } from '@helpers/cart'
import { addToDataLayer_nextgen } from '@helpers/google-tag-manager'

const emboldenDescription = description => {
  const freeRegex = /free/i
  const percentRegex = /\d+\s?%\s?(?:off)?/i

  if (freeRegex.test(description) || percentRegex.test(description)) {
    const combinedPattern = new RegExp(`(${freeRegex.source}|${percentRegex.source})`, 'gi')
    const parts = description.split(combinedPattern)
    const shouldBold = part => freeRegex.test(part) || percentRegex.test(part)

    return (
      <>
        {parts.map((part, index) =>
          shouldBold(part) ? (
            // eslint-disable-next-line
            <strong style={{ textTransform: 'uppercase' }} key={`${index}-${part}`}>
              {part}
            </strong>
          ) : (
            part
          ),
        )}
      </>
    )
    // eslint-disable-next-line
  } else if (description.trim().startsWith('$')) {
    const [firstWord, ...rest] = description.split(' ')
    return (
      <>
        <strong>{firstWord}</strong> {rest.join(' ')}
      </>
    )
  } else {
    return <>{description}</>
  }
}

const BonusBuyContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  padding: '16px 0',
  height: '100%',
  gap: 8,
  borderTop: '1px solid rgba(0, 0, 0, 0.12)',
}))

const BonusBuyHeader = styled('h3')(() => ({
  fontSize: '20px',
  fontWeight: 600,
  color: '#E11F21',
}))

const BonusBuyDescription = styled('h4')(() => ({
  fontSize: '16px',
  fontWeight: 400,
}))

const BonusBuyItemsContainer = styled('div')(({ isColumnLayout, buttonIsAdjacent }) => ({
  paddingTop: '8px',
  display: 'flex',
  flexDirection: isColumnLayout ? 'column' : 'row',
  gap: isColumnLayout ? 24 : 16,
  width: buttonIsAdjacent ? '80%' : '100%',
}))

const BonusBuyItem = styled('div')(({ isColumnLayout, isMobile }) => ({
  height: '100%',
  width: isMobile ? '100%' : 'auto',
  display: 'flex',
  flexDirection: isColumnLayout ? 'column' : 'row',
  alignItems: isColumnLayout ? 'start' : 'center',
  gap: 24,
}))

const ImageInfoContainer = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: 8,
}))

const BonusBuyInfo = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-evenly',
  alignItems: 'start',
  gap: 4,
}))

const StyledFlag = styled('div')(({ isBonusBuy = false }) => ({
  backgroundColor: isBonusBuy ? '#E11F21' : '#E6EEF6',
  color: isBonusBuy ? 'white' : 'black',
  padding: '4px',
  borderRadius: '3px',
  fontSize: '10px',
  fontWeight: 600,
  textTransform: 'uppercase',
}))

const PriceContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: 8,
}))

const AddOfferButton = styled(Button)(({ isMobile }) => ({
  minHeight: '35px',
  fontSize: '14px',
  whiteSpace: 'nowrap',
  fontWeight: 600,
  padding: '0 16px',
  width: isMobile ? '100%' : 'auto',
  alignSelf: 'flex-end',
}))

const ContentAndButtonContainer = styled(Box)(({ buttonIsAdjacent, isMobile }) => ({
  display: 'flex',
  flexDirection: isMobile || !buttonIsAdjacent ? 'column' : 'row',
  alignItems: 'flex-start',
  gap: (isMobile || !buttonIsAdjacent) && 24,
}))

const BonusBuyTitles = {
  'BUY X GET Y DISCOUNT': 'Buy Both & Save ',
  'BUY X AND Y GET Z DISCOUNT': 'Bonus Buy',
}

const BonusBuyOffer = ({ product, isMobile, isBonusBuy, bonusBuyDiscount }) => {
  const [bBProducts, setBBProducts] = useState()
  const { region, zone } = getRegionZone()
  const priceZone = `${region}_${zone}`
  const promotionZone = `${region}_0`
  const regionHasBonusBuy = product?.saleFlag?.[region]?.text?.includes('BONUS')
  const promotion = product?.promotions?.[promotionZone] || null
  const isBogo = promotion?.offer_template === 'BUY X AND Y GET Z DISCOUNT'
  const productMatchesBB =
    product.sku === promotion?.target_skus[0].sku && promotion?.offer_template === 'BUY X GET Y DISCOUNT'
  const isColumnLayout = isMobile || bBProducts?.length > 2
  const buttonIsAdjacent = bBProducts?.length < 2
  const isNotSingularBonusBuy = promotion?.target_skus?.length > 3

  const getDiscountValue = (discountVal, discountType) => {
    if (discountType === 'PERCENT OFF') {
      const bonusBuyPrice =
        bBProducts?.[bBProducts?.length - 1]?.pricing[`${priceZone}_sale_price`] ||
        bBProducts?.[bBProducts?.length - 1]?.pricing?.default_price
      const discountPrice = bonusBuyPrice - bonusBuyPrice * (discountVal / 100)
      return discountPrice
    }
    return discountVal
  }

  const getShouldShowBonusBuy = () => {
    if (productMatchesBB && bonusBuyDiscount) {
      return false
    }
    if (!productMatchesBB && isBonusBuy) {
      return false
    }
    if (!regionHasBonusBuy || !bBProducts || isNotSingularBonusBuy) {
      return false
    }
    return true
  }
  const shouldShowBonusBuy = getShouldShowBonusBuy()

  const addProductToCart = () => {
    // [GTM] - Track when user adds an item to cart
    addToDataLayer_nextgen('cart_bonusBuy--addToCart', {
      sku: bBProducts[0].sku,
      title: bBProducts[0].title,
    })

    if (isBogo) {
      const product_price = bBProducts[0].pricing[priceZone]
      return addToCart({
        product: bBProducts[0],
        price: product_price,
        activeAddons: null,
        warrantyEnabled: false,
        promotion: { ...promotion, promotionItemSku: product.sku },
        quantity: 2,
      })
      // eslint-disable-next-line
    }
    return bBProducts.forEach(bbProduct => {
      const product_price = bbProduct.pricing[priceZone]
      return addToCart({
        product: bbProduct,
        price: product_price,
        activeAddons: null,
        warrantyEnabled: false,
        promotion: { ...promotion, promotionItemSku: product.sku },
        quantity: 1,
      })
    })
  }

  const getBBProductsList = () => {
    const list = promotion.target_skus
    if (list.length === 1 && isBogo) {
      list.push(promotion.target_skus[0])
    }
    return list
  }

  const fetchBBProducts = async () => {
    if (promotion?.target_skus?.length) {
      const list = getBBProductsList()
      const results = await Promise.all(list.map(item => fetchProductBySku(item.sku)))
      setBBProducts(results)
    }
  }

  useEffect(() => {
    fetchBBProducts()
    // eslint-disable-next-line
  }, [])

  const title = BonusBuyTitles[promotion?.offer_template] || 'Bonus Buy'
  if (!shouldShowBonusBuy) return null

  return (
    <BonusBuyContainer>
      <BonusBuyHeader>{title}</BonusBuyHeader>
      <BonusBuyDescription>{emboldenDescription(promotion?.offer_description)}</BonusBuyDescription>
      <ContentAndButtonContainer buttonIsAdjacent={buttonIsAdjacent} isMobile={isMobile}>
        <BonusBuyItemsContainer buttonIsAdjacent={buttonIsAdjacent} isColumnLayout={isColumnLayout}>
          {bBProducts &&
            bBProducts.map((item, index) => {
              const defaultItemPrice = item.pricing[`${priceZone}_list_price`]
              const discountValue = getDiscountValue(promotion?.discount_value, promotion?.discount_type)
              const isFirstInList = bBProducts?.length > 1 && index === 0
              return (
                <BonusBuyItem key={item.sku} isColumnLayout={isColumnLayout} isMobile={isMobile}>
                  {index !== 0 && <AddCircleOutlineIcon style={{ color: '#999999', alignSelf: 'center' }} />}
                  <ImageInfoContainer>
                    <img
                      src={item?.primary_image}
                      alt={item?.title}
                      style={{ maxWidth: '100%', maxHeight: '75px', paddingRight: '4px' }}
                    />
                    <BonusBuyInfo>
                      {isFirstInList ? (
                        <StyledFlag isBonusBuy={false}> Buy</StyledFlag>
                      ) : (
                        <StyledFlag isBonusBuy>Bonus Buy</StyledFlag>
                      )}
                      <h5
                        style={{
                          fontSize: '14px',
                          fontWeight: 500,
                        }}
                      >
                        {item?.title}
                      </h5>
                      <PriceContainer>
                        {isFirstInList ? (
                          <Price price={currencyFormatUS(defaultItemPrice, true)} />
                        ) : (
                          <>
                            <Price strikeThrough price={currencyFormatUS(defaultItemPrice, true)} />
                            <PriceAtom bold sale price={discountValue} style={{ color: 'red' }} />
                          </>
                        )}
                      </PriceContainer>
                    </BonusBuyInfo>
                  </ImageInfoContainer>
                </BonusBuyItem>
              )
            })}
        </BonusBuyItemsContainer>
        <AddOfferButton
          isMobile={isMobile}
          onClick={() => {
            addProductToCart()
          }}
          color="primary"
          variant="contained"
          aria-label={`Add To Cart for ${title} bonus buy`}
        >
          ADD OFFER TO CART
        </AddOfferButton>
      </ContentAndButtonContainer>
    </BonusBuyContainer>
  )
}

BonusBuyOffer.propTypes = {
  product: PropTypes.shape({
    saleFlag: PropTypes.object,
    promotions: PropTypes.object,
    sku: PropTypes.string,
  }),
  isMobile: PropTypes.bool,
  isBonusBuy: PropTypes.bool,
  bonusBuyDiscount: PropTypes.bool,
}

export default BonusBuyOffer
